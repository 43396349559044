import React, { useState } from "react";
import logo from '../images/Fabulous-logo-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { Navbar, NavbarToggler, NavbarBrand, Collapse, NavItem, NavLink, Nav } from 'reactstrap';

const PrimaryHeader = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggle = () => setIsCollapsed(!isCollapsed);

  return (
    <Navbar className="py-0 sticky-top" expand='lg'>
      <div id='navbar-fit' className="d-flex flex-nowrap">

        <NavbarBrand className="navbar-brand mr-0 p-0" href="/">
          <img className='primary-logo mb-2' src={logo} alt='Fabulous Paints Logo' /><span id='title-fabulous'>FABULOUS</span><span id='title-paints'>PAINTS</span>
        </NavbarBrand>

        <NavbarToggler className="mb-1" onClick={toggle} >
          <FontAwesomeIcon className='px-1 cust-toggler' icon={faBars} />
        </NavbarToggler>
      </div>

      <Collapse isOpen={!isCollapsed} navbar>
        <Nav className="navbar-nav ml-auto" navbar>
          <NavItem>
            <NavLink className='text-light' href="#whyus">
              WHY US
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className='text-light' href="#ourwork">
              OUR WORK
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className='text-light' href="#about">
              ABOUT
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className='text-light' href="#contact">
              CONTACT
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar >
  )
}
export default PrimaryHeader;