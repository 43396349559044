import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default () => (
  <div className='navbar-secondary text-center'>
    <a className='navbar-secondary-item' href="tel:613-868-0056"><FontAwesomeIcon className='px-1 navbar-secondary-icons w-100' style={{ maxWidth: '40px', maxHeight: '40px' }} icon={faPhone} /><span id="et-info-phone">613-868-0056</span></a>
    <a className='navbar-secondary-item' href="mailto:fabricio@fabulouspaints.com"><FontAwesomeIcon className='px-1 navbar-secondary-icons w-100' style={{ maxWidth: '40px', maxHeight: '40px' }} icon={faEnvelope} /><span id="et-info-email">fabricio@fabulouspaints.com</span></a>
  </div>
)