import React from "react";
import logo from '../images/fabpaintsbrush.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Row, Col, Input, Button, Form } from 'reactstrap';

export default () => (
  <Row>
    <Carousel className='col-sm-12 col-md-8 px1-0 intro-carousel' dynamicHeight={true} showThumbs={false} showStatus={false} infiniteLoop={true} interval={3000} transitionTime={600} autoPlay={true}>
      <img src={require('../images/Orleans-Interior-Design-Painting-1-of-1ed-1024x684.jpg')} alt="Orleans Interior Painting Project 1" />
      <img src={require('../images/Ottawa-Painting-1024x768.jpg')} alt="Residential Painting Project 1" />
      <img src={require('../images/Orleans-Interior-Design-Painting-1-of-1-2ed-1024x684.jpg')} alt="Orleans Interior Painting Project 2" />
      <img src={require('../images/Ottawa-Painting-2-1024x768.jpg')} alt="Residential Painting Project 2" />
      <img src={require('../images/IMG-20181030-WA0010-1024x576.jpg')} alt="Residential Painting Project 3" />
    </Carousel>

    <Col id='contact' className='text-center' xs='12' md='4'>
      <img id='contact-logo' src={logo} alt='Fabulous Paints Logo' />
      <h1 id='intro-phrase'>PRETTY. GREAT. PAINTERS</h1>

      <Form id='contact-form' name='GetAQuote' className='mx-auto' method="post" netlify-honeypot="bot-field" data-netlify="true">
        <Input type="hidden" name="bot-field" aria-label='netlify-hidden' />
        <Input className='contact-input mb-4' type="text" name="Name" placeholder='Name' aria-label='Name' />
        <Input className='contact-input mb-4' type="email" name="Email" placeholder='Email' aria-label='Email' />
        <Input className='contact-input mb-4' type="tel" name="Phone" placeholder='Phone' aria-label='Phone' />
        <Input className='contact-input mb-4' type="tel" name="Phone" placeholder='Phone' aria-label='Phone' />
        <Input className='contact-input mb-4' type="select" name="Type" aria-label='Type of Project'>
          <option value='Residential'>Residential</option>
          <option value='Commercial'>Commercial</option>
        </Input>

        <Button className='contact-submit d-block w-50 mx-auto' aria-label='Submit'>GET A QUOTE</Button>
      </Form>

    </Col>
  </Row>
)