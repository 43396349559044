import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

export default () => (
  <footer id='footer' className="text-white p-2">
    <div className='w-75 mx-auto'>
      <div id='footer-icons-parent' className='text-center'>
        <a href='https://www.facebook.com/fabulouspaints/' aria-label='Our Facebook Page'><FontAwesomeIcon className='mx-2 footer-icons' icon={faFacebook} /></a>
        <a href='https://www.instagram.com/fabulous.paints/?hl=en' aria-label='Our Instagram Page'><FontAwesomeIcon className='mx-2 footer-icons' icon={faInstagram} /></a>
      </div>

      <p id='footer-copy-parent' className='d-inline-block mb-0'><span id='footer-copy'>&copy; Fabulous Paints {new Date().getFullYear()}</span></p>
    </div>
  </footer >
)