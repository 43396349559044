import React from "react"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet';

import SecondaryHeader from '../components/SecondaryHeader';
import PrimaryHeader from '../components/PrimaryHeader';
import IntroSection from "../components/IntroSection";
import About from '../components/About';
import WhyUs from "../components/WhyUs";
import InstaFeed from "../components/InstaFeed";
import Footer from '../components/Footer';

import 'bootstrap/dist/css/bootstrap.css';
import '../main.css';
import favicon from '../images/Fabulous-logo-1.png';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Helmet link={[
      { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${favicon}` }
    ]} />

    <SecondaryHeader />
    <PrimaryHeader />

    <IntroSection />
    <About />
    <WhyUs />
    <InstaFeed />
    <Footer />
  </>
)

export default IndexPage