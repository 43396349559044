import React from "react";

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import img1 from '../images/insta/1.jpg'
import img2 from '../images/insta/8.jpg'
import img3 from '../images/insta/3.jpg'
import img4 from '../images/insta/4.jpg'
import img5 from '../images/insta/5.jpg'
import img6 from '../images/insta/6.jpg'
import img7 from '../images/insta/7.jpg'
import img8 from '../images/insta/8.jpg'

let images = [
  <div className='p-4' >
    <img src={img1} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img2} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img3} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img4} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img5} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img6} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img7} width={500} height={500} />
  </div>,
  <div className='p-4' >
    <img src={img8} width={500} height={500} />
  </div>
];

let responsive = {
  0: { items: 1 },
  1024: { items: 4 },
}

export default () => (
  <div id='ourwork' className='mt-3'>
    <p className='h5 text-center font-weight-bold mb-0'>FOLLOW OUR WORK ON INSTAGRAM</p>
    <AliceCarousel className='d h-50' mouseDragEnabled dotsDisabled={false} items={images} responsive={responsive} autoPlay={true} autoPlayInterval={4000} buttonsDisabled={true} />
  </div>
)