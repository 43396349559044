import React from "react";
import profilePic from '../images/fabricio-1-2-683x1024.jpg';
import { Row, Col } from 'reactstrap';

export default () => (
  <>
    <p id='about' className='text-center mt-4'><strong>INTERIOR RESIDENTIAL & COMMERCIAL PAINTING SERVING OTTAWA & SURROUNDING AREAS.</strong></p>

    <Row className='mb-2'>
      <Col xs='12' lg={{ size: 3, offset: 3 }}>
        <img src={profilePic} alt='Owner - Fabricio Rodriguez' id='about-profilepic' className='w1-50 mx-auto d-block' />
      </Col>
      <Col xs='12' lg={{ size: 3 }}>
        <div id='about-blurb'>
          <h4 id='about-owner'>About Fabricio Rodriguez - Owner</h4>
          <blockquote id='about-quote' className='mt-4'><span className='font-weight-bold'>"</span>Coming from a Business Management and Marketing background, with 8 years of experience in the trades, I developed a love for painting. My job is not only to perform a task, but to provide the best experience possible, by treating your home or business as if it were my own.<span className='font-weight-bold'>"</span></blockquote>
        </div>
      </Col>
    </Row>
  </>
)