import React from "react";
import rietkerkLogo from '../images/riertkiert-1-1024x1024.png';
import silverbrookLogo from '../images/BM-silver-brook-1024x1024.png';
import byfabiolaLogo from '../images/fabiola-and-co.png';

export default () => (
  <section id='whyus' className=' mt-3'>
    <h3 className='text-center font-weight-bold pt-4'>WHY US?</h3>

    <div className='row mt-4 px-51 whyus-cols'>
      <div className='col-sm-12 col-lg-4 pl-5 whyus-item'>
        <h4 className='font-weight-bold text-center'>WE ARE FLEXIBLE</h4>
        <p className='whyus-content'>Whether you are a home or business owner, we will work around your hours with minimum disruption.</p>
      </div>
      <div className='col-sm-12 col-lg-4 px-4 whyus-item'>
        <h4 className='font-weight-bold text-center'>QUALITY WORK</h4>
        <p className='whyus-content'>We take pride in our work by shopping local at Benjamin Moore. Their zero to low VOC levels guarantee low odour with quality finish, which is why it’s our go-to paint! Our work is backed by a two year quality guarantee. Should the paint bubble, peel or chip within 24 months of your project being completed, we will arrange for these areas to be addressed.</p>
      </div>
      <div className='col-sm-12 col-lg-4 pr-5 whyus-item'>
        <h4 className='font-weight-bold text-center'>OUR PARTNERSHIPS</h4>
        <p className='whyus-content'>We are trusted in the industry by our partnership with other local business owners who we recommend for all your home renovation needs!</p>
      </div>
    </div>

    <div className='col-sm-12 col-lg-6 mx-auto'>
      <div className='row pb-4'>
        <div className='col-sm-12 col-lg-4'>
          <a href='http://rietkerk.ca/'><img src={rietkerkLogo} alt='Rietkerk Construction Logo' className='w-50 mx-auto d-block' /></a>
        </div>
        <div className='col-sm-12 col-lg-4 pt-2'>
          <a href='https://www.benjaminmoore.com/en-us'><img src={silverbrookLogo} alt='Benjamin Moore Paints Logo' className='w-50 mx-auto d-block' /></a>
        </div>
        <div className='col-sm-12 col-lg-4 pt-5'>
          <a href='https://fabiolaandcodesign.com/'><img src={byfabiolaLogo} alt='Fabiola and Co Design Logo' className='w-75 mx-auto d-block mt-1' /></a>
        </div>
      </div>

    </div>
  </section>
)